import React, {useContext,useRef} from 'react'
import styled from 'styled-components/macro'
import {App} from "../App";
import {withRouter} from 'react-router-dom'
import {Icons} from "../components/Icons";
import { NotificationManager } from 'react-notifications';

const StyledContainer = styled.div`
  display: flex;
  box-shadow: 0 0 2px #888;
  margin: 1rem 0;
  align-items: center;
  small {
    color: #fff;
  }
`;

const StyledURIContainer = styled.div`
  height: 0;
  width: 0;
  opacity: 0;
`;

const StyledCopyToClipBoard = styled.div`
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 30px;
  background-color: #ddd;
  padding: .1rem 1rem;
  box-shadow: 0 3px 10px -2px rgba(0,0,0,.6);
  font-weight: 600;
  color: #424242;
  z-index: 100;
  cursor: pointer;
  margin-right: 1rem;
  
  svg {
    width: 24px;
    height: 24px;
    margin-right: .5rem;
    
    fill: #424242;
  }
`;


export const FavoriteExport = withRouter(function(props){

  const state = useContext(App.prototype.StateContext);
  const favorites = state.getIn(['favorites']).toJS();
  const ref = useRef(null);

  return (
    <StyledContainer className={props.className || ''}>
      <StyledURIContainer readonly ref={ref}>{`${window.location.origin}/sectors?sector=4&favorites=${favorites.join(',')}`}</StyledURIContainer>
      <StyledCopyToClipBoard
        role={"button"}
        tabIndex={8}
        onKeyPress={event => {
          if(event.key === 'Enter'){
            event.target.click();
          }
        }}
        onClick={() => {
          ref.current.contentEditable = true;
          ref.current.focus();
          document.execCommand('selectAll',false,null);
          document.execCommand("copy");
          NotificationManager.success("Link copied!");
          ref.current.contentEditable = false;
        }}>
        <Icons.copy/>
        Copy link
      </StyledCopyToClipBoard>
      <small>Copy the link to your clipboard to find your favorites later</small>
    </StyledContainer>
  );
})
