import React, {useState} from 'react'
import styled, {css} from "styled-components/macro";
import {Route} from "react-router";
import {Home} from "./containers/Home";
import {Sector} from "./containers/Sector";
import {Cards} from "./containers/Cards";
import {BackButton} from "./components/BackButton";
import {Link} from "react-router-dom";
import {ColorRow} from "./components/ColorRow";
import {getFromLS} from "./localstorage";
import {StartModal} from "./components/StartModal";
import { breakpoint, breakpointHeight, Container } from "./styled";

const Section = styled.section`
    padding: 2rem 3rem;
`;

const StyledButton = styled.button`
    background-color: #008782;
    padding: 1rem 1rem;
    color: white;
    border: none;
    border-radius: 40px;

    &:focus {
        outline: none;
    }
`;

const StyledOuterContainer = styled.div`
  position: relative;
`;

const StyledFooter = styled.footer`
  background-color: white;
  
  p {
    width: 100%;
    max-width: 450px;
    text-align: center;
  }
`;

const StyledContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 3.5rem 2rem 1.5rem;
  overflow: hidden;
`;

const StyledLinkContainer = styled.div`
  display: flex;
  color: ${props => props.theme.linkColor };

  a {
    text-decoration: none;
    margin: 0 1rem;
    color: ${props => props.theme.linkColor };
  }
`;

const StyledFooterLogo = styled.div`
  margin: 3rem 0 1.5rem;
  width: 100%;
  box-sizing: border-box;

  display: flex;
  justify-content: space-between;

  img {
    max-height: 50px;
    max-width: 20%;
    flex: 0 0 auto;
    align-self: center;
    object-fit: scale-down;
  }
  
  img + img {
    margin-left: 2rem;
  }
  
  span.more-icons {
    flex: 1;
    display:flex;
    justify-content: flex-end;
    align-items: center;
    
    img {
      height: 100%;
      object-position: right bottom;
    }
  }  

  img.hsy-icon {
    max-height: 80px;
    object-position: left bottom;
  }

  
`;

export const Routing = function (props) {

  const ls = getFromLS();
  const [ welcomeShown, setWelcomeShown ] = useState(Boolean(ls && ls.welcomeShown));

  if(!welcomeShown)
    return <StartModal onClose={() => setWelcomeShown(true) }/>;

  return (
    <StyledOuterContainer>
      <ColorRow/>

      <Route path="/" exact component={Home}/>
      <Route path="/sectors" component={Sector}/>
      <Route path="/cards" component={Cards}/>
      <Route path="/sectors" render={ props => {
        return <BackButton {...props} path={""} />
      }}/>
      <Route path="/cards" render={ props => {
        return <BackButton {...props} path={""} />
      }}/>

      <Route path="/sectors" render={props => {
        return <Section
          css={css`
              background-color: #008782;
              display: flex;
              padding-bottom: 0;
              align-items: center;
              justify-content: center;
              flex-direction: column;
              h1 {
                  color: white;
              }
              background-image: url(${require("./assets/infografiikat/LCD-kortit-EN.png")});
              background-size: contain;
              background-repeat: no-repeat;
              background-position: center;
              height: 150px;

              @media ${breakpoint.md} {
                  height: 200px;
              }
          `}
      >
          
          <StyledButton
              css={css`
                  padding: 1rem 2rem;
                  font-size: 1.3em;
                  font-family: "Montserrat", sans-serif;
                  font-weight: 600;
                  cursor: pointer;
                  box-shadow: 0 12px 4px -8px rgba(0,0,0,.3);
                  color: #008782;
                  background-color: #ffffff;
              `}
              onClick={() => {
                  props.history.push("/cards");
                  window.scrollTo(0, 0);
              }}
          >
              Download Action Cards
          </StyledButton>
          <styledImage
              src={require("./assets/infografiikat/LCD-kortit-EN.png")}
          />
          

      </Section>
      }}/>

      <StyledFooter>
        <StyledContainer>
          <p>This service is produced by Helsinki Region Environmental Services Authority (HSY). It has been executed as part of the Interreg Europe SMART-MR program and has been supported by the European Regional Development Fund.</p>
          <StyledLinkContainer>
            <Link to={'/'}>Terms of Use</Link>
            |
            <Link to={'/'}>Contact Us</Link>
          </StyledLinkContainer>
          <StyledFooterLogo>
            <img className={'hsy-icon'}
                 src={require('./assets/logo/hsy-logo.svg')}
                 alt={"HSY logo"}/>
            <span className={"more-icons"}>
              <img src={require('./assets/logo/ym-logo.svg')}
                   alt={"Ministry of the Environment logo"}/>
              <img src={require('./assets/logo/eu-logo.png')}
                   alt={"European Union logo"}/>
              <img src={require('./assets/logo/smart-mr-logo.png')}
                   alt={"SMART-MR Interreg Europe logo"}/>
            </span>
          </StyledFooterLogo>
        </StyledContainer>
      </StyledFooter>
      <ColorRow/>
    </StyledOuterContainer>
  );
}