import React, { useContext, useState } from "react";
import styled, { css } from "styled-components/macro";
import { Icons } from "./Icons";
import { breakpoint } from "../styled";
import { App } from "../App";

const StyledLangButton = styled.div`
    position: absolute;
    top: 40px;
    right: 20px;
    width: auto;
    padding: 5px 15px;
    border-radius: 10px;
    background: white;
    border: 2px solid #1e8177;
    color: #1e8177;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    z-index: 10;
    text-alight: right;

    @media ${breakpoint.md} {
        top: 40px;
        right: 40px;
    }
`;

const StyledRoundButton = styled.div`
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-alignt: center;

    @media ${breakpoint.md} {
        width: 100px;
        height: 100px;
    }
    border-radius: 50%;
    img {
        border-radius: 50%;
        height: 50px;
        width: 50px;
        @media ${breakpoint.md} {
            width: 75px;
            height: 75px;
        }
        @media ${breakpoint.xl} {
            width: 100px;
            height: 100px;
        }
    }
    img:hover {
        box-shadow: 3px 3px 5px rgba(0,0,0, .3);
        transform: translate(-3px, -3px);
        transition: all 0.2s ease;
    }
    p {
        position: absolute;
        top: 100%;
        @media ${breakpoint.md} {
            top: 80%;
        }
        @media ${breakpoint.xl} {
            top: 100%;
        }
        line-height: 1.3;
        margin: 0;
        padding: 0;
        text-align: center;
    }
    font-size: 12px;
    font-weight: bold;
    
    @media ${breakpoint.md} {
        font-size: 16px;
    }
`;

const Infobox = (props) => {
    console.log(props)
    return (
        <div css={css`
                    position: relative;
                    top: -13vW;
                    left: 50%;
                    transform: translate(-50%);
                    background-color: white;
                    padding: 1rem;
                    border-radius: 10px;
                    box-shadow: 3px 3px 10px rgba(0, 135, 130, 0.6);
                    width: 80%;
                    @media ${breakpoint.xl} {
                        top: -200px;
                        width: 70%;
                    }
                    ul {
                        margin: auto;
                        text-align: left;
                        width: 80%;
                        padding-bottom: 1rem;
                    }
                `}>
                    {props.content == 1 &&
                        <div>
                            <h3>Create spaces supporting local identity</h3>
                            <ul>
                                <li>Green infrastructure, safety and comfort</li>
                                <li>Communal spaces</li>
                            </ul>
                        </div>
                    }
                    {props.content == 2 &&
                        <div>
                            <h3>Support Low-Carbon Business Development</h3>
                            <ul>
                                <li>Leadership</li>
                                <li>Enabling and integrating small-scale businesses in the service selection</li>
                                <li>City logistics and last mile solutions</li>
                            </ul>
                        </div>
                    }
                    {props.content == 3 &&
                        <div>
                            <h3>Promote Cycling</h3>
                            <ul>
                                <li>Connecting the eastern and western side </li>
                                <li>Bicycle parking at the station</li>
                                <li>Improving the quality of cycling environment</li>
                            </ul>
                        </div>
                    }
                    {props.content == 4 &&
                        <div>
                            <h3>Climate-Smart Housing</h3>
                            <ul>
                                <li>Energy efficiency of existing buildings and infrastructure</li>
                                <li>Small-scale, decentralized energy solutions and innovations</li>
                                <li>Solar energy production</li>
                                <li>Energy efficient new buildings</li>
                                <li>Buildings' life-cycle carbon footprint</li>
                            </ul>
                        </div>
                    }
                    {props.content == 5 &&
                        <div>
                            <h3>Circular Economy</h3>
                            <ul>
                                <li>Circular economy in infrastructure and construction</li>
                            </ul>
                        </div>
                    }
                </div>
     );
 }


export const MalmiGraph = function (props) {
    const [ selectedContent, setselectedContent ] = useState(0);

    return (
        <div>
            <p>Click the theme icons to see the measures prioritized in the planning of low-carbon Malmi station district.</p>
            <div
                css={css`
                position: relative;
                width: 100%;
                height: 100%;
            `}>
                <img
                css={css`
                    width: 100%;
                    height: auto;
                `}
                src={require("../assets/infografiikat/malmi-bg-map.svg")}
                alt="Prosessikaavio, jossa ensimmäinen vaihe on 'Alueen ominaispiirteiden ja kehittämisedellytysten tunnistaminen', toinen vaihe on 'Toimenpiteiden priorisointi vuorovaikutteisessa työpajassa' ja kolmas, viimeinen vaihe on 'Vähähiilitiekartan koonti aikajanalle'. "
                />
                <div css={css`
                    position: absolute;
                    top: 44%;
                    left: 50%;
                    transform: translate(-50%);
                    `}
                ><h4>Malmi <br />station area</h4></div>
                <StyledRoundButton
                    css={css`
                        position: absolute;
                        top: 50%;
                        left: 12%;
                        @media ${breakpoint.md} {
                            left: 12.5%;
                        }
                    `}
                    onClick={() => setselectedContent(1)}
                >
                    <img
                        src={require("../assets/infografiikat/LCD_1.svg")}
                        alt="Alueen identiteettiä ja ilmettä tukevat tilat"
                    />
                    <p>Local Identity</p>
                </StyledRoundButton>
                <StyledRoundButton
                    css={css`
                        position: absolute;
                        top: 21%;
                        left: 19%;
                    `}
                    onClick={() => setselectedContent(2)}
                >
                    <img
                        src={require("../assets/infografiikat/LCD_2.svg")}
                        alt="Alueen identiteettiä ja ilmettä tukevat tilat"
                    />
                    <p>Business Development</p>
                </StyledRoundButton>
                <StyledRoundButton
                    css={css`
                        position: absolute;
                        top: 9%;
                        left: 50%;
                        transform: translate(-50%);
                    `}
                    onClick={() => setselectedContent(3)}
                >
                    <img
                        src={require("../assets/infografiikat/LCD_3.svg")}
                        alt="Alueen identiteettiä ja ilmettä tukevat tilat"
                    />
                    <p>Cycling</p>
                </StyledRoundButton>
                <StyledRoundButton
                    css={css`
                        position: absolute;
                        top: 21%;
                        right: 19%;
                    `}
                    onClick={() => setselectedContent(4)}
                >
                    <img
                        src={require("../assets/infografiikat/LCD_4.svg")}
                        alt="Alueen identiteettiä ja ilmettä tukevat tilat"
                    />
                    <p>Housing</p>
                </StyledRoundButton>
                <StyledRoundButton
                    css={css`
                        position: absolute;
                        top: 50%;
                        right: 12%;
                    `}
                    onClick={() => setselectedContent(5)}
                >
                    <img
                        src={require("../assets/infografiikat/LCD_5.svg")}
                        alt="Alueen identiteettiä ja ilmettä tukevat tilat"
                    />
                    <p>Circular Economy</p>
                </StyledRoundButton>
                
            </div>
            {selectedContent ? 
                    <Infobox content={selectedContent}/>
             : " "}
        </div>
    );
};