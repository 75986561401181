import React, { useContext, useState, useEffect } from "react";
import styled, { css } from "styled-components/macro";
import { App } from "../App";
import { darken, math } from "polished";
import { breakpoint, breakpointHeight, Container } from "../styled";
import { Icons } from "../components/Icons";
import { MalmiGraph } from "../components/MalmiGraph";

const StyledHeader = styled.header`
/*
  height: ${(props) => {
      if (props.theme.windowHeight)
          return math(
              `${props.theme.windowHeight}px - ${props.theme.colorRowHeight}`
          );
      return `${props.theme.windowHeight}px`;
  }};
 */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  background-color: white;
  padding: 2rem;
  box-sizing: border-box;
  margin: 0;
  
  min-height: 500px !important;

  @media ${breakpoint.md} {
    padding: 2rem;
  }
`;

const StyledButton = styled.button`
    background-color: #008782;
    padding: 1rem 1rem;
    color: white;
    border: none;
    border-radius: 40px;

    &:focus {
        outline: none;
    }
`;

const Styled = styled.div``;
const Section = styled.section`
    padding: 2rem 3rem;
`;

const margin = "12px";
const StyledCardButtonContainer = styled.div`
  position: relative;
  width: 100%;
  z-index: 1;
  margin: 10px 0 3rem 0;
  flex: 1 0;
  
  // IOS
  @supports (-webkit-overflow-scrolling: touch) {
    flex: 0 1;
  }
  
  max-height: 100vw;
  
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 1fr;
  -ms-grid-rows: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  grid-column-gap: ${margin};
  grid-row-gap: ${margin};
  
  @media ${breakpoint.md} and ${breakpointHeight.lg} {
    width: 90%;
    margin: 5vh 0 10vh 0;
  }
  
`;

const StyledCardButton = styled.div`
    border-radius: 10px;
    order: ${(props) => props.sector};
    overflow: hidden;
    box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.3);
    display: flex;
    min-height: 6rem;
    @media ${breakpoint.md} {
        min-height: 8.5rem;
    }
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        margin: ${margin};
    }
`;

const StyledCardButtonImage = styled.img`
  flex: 0 0 0%;
  background-size: cover;
  background-repeat: no-repeat;
  order: ${(props) => (props.sector % 2 === 1 ? 1 : 0)};
  
  @media ${breakpoint.md} and ${breakpointHeight.sm} {
    flex: 0 0 36%;
  }
  
  background-image: url(${(props) => {
      switch (props.sector) {
          case 0:
              return require("../assets/valikkokuvat/valikko-maankaytto.jpg");
          case 1:
              return require("../assets/valikkokuvat/valikko-asuminen.jpg");
          case 2:
              return require("../assets/valikkokuvat/valikko_liikenne.jpg");
          case 3:
              return require("../assets/valikkokuvat/valikko_palvelut.jpg");
      }
  }});
  
  background-position: ${(props) => {
      switch (props.sector) {
          case 0:
              return "70% center";
          case 1:
              return "center center";
          case 2:
              return "center center";
          case 3:
              return "center center";
      }
  }};
`;

const StyledCardButtonLabel = styled.div`
  flex: 0 0 100%;
  height: 100%;
  background-color: ${(props) => props.theme.colors[props.sector]};
  order: ${(props) => (props.sector % 2 === 1 ? 0 : 1)};
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.1em;
  color: ${(props) => (props.sector < 2 ? "#222" : "#fff")};
  position: relative;
  box-sizing: border-box;
  padding: 2rem .5rem;
  
  @media ${breakpoint.md} and ${breakpointHeight.sm} {
    padding: 3rem 1rem;
    font-size: 1.25em;
    flex: 0 0 66%;
  }
  
  
  
  &::after {
    color: white;
    content: "";
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    background-image: url(${(props) => {
        switch (props.sector) {
            case 0:
                return require("../assets/valikon-taustaikonit/nav-bg-maankaytto.svg");
            case 1:
                return require("../assets/valikon-taustaikonit/navi-bg-asuminen.svg");
            case 2:
                return require("../assets/valikon-taustaikonit/nav-bg-liikenne.svg");
            case 3:
                return require("../assets/valikon-taustaikonit/nav-bg-palvelut.svg");
        }
    }});
    opacity: 0.3;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
  }
  
  span {
    width: 80%;
    text-align: center;
    padding: 10px 0;
  }
`;

const StyledCO2 = styled.div`
    position: absolute;
    width: 80px;

    @media ${breakpoint.md} {
        width: 140px;
    }

    background-color: white;
    border-radius: 50%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 3px 4px 2px rgba(0, 0, 0, 0.4);
    z-index: 5;

    span.outer {
        overflow: hidden;
        height: 0;
        padding-top: 100%;
    }

    span.inner {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    svg {
        position: absolute;
        width: 75%;
        height: 75%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        opacity: 0.5;
    }
`;

const StyledLogoContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    align-items: center;

    @media ${breakpoint.md} {
    }
`;

const StyledHSYLogo = styled.div`
    flex: 1 0 33%;
    order: 1;
    height: 40px;
    justify-content: flex-start;
    cursor: pointer;

    @media ${breakpoint.md} {
        height: 70px;
    }
`;

const StyledExtraIcons = styled.div`
    display: flex;
    order: 4;
    height: 40px;
    border-top: 1px solid #ccc;
    margin-top: 18px;
    padding-top: 18px;
    justify-content: space-between;
    width: 100%;

    span {
        flex: 0 0 30%;
        display: flex;
        align-items: flex-end;
        justify-content: center;
    }

    span:first-of-type {
        justify-content: flex-start;
    }

    span:last-of-type {
        justify-content: flex-end;
    }

    @media ${breakpoint.md} {
        span {
            flex: 0 0 15%;
        }

        span + span {
            margin-left: 12px;
        }
    }

    @media ${breakpoint.lg} {
    }
`;

const StyledArrow = styled.div`
    display: flex;
    flex: 1 0 25%;
    order: 3;
    height: 50px;
    justify-content: center;
    align-items: bottom;
    position: relative;

    svg {
        height: 50px;
        position: absolute;

        path {
            fill: ${(props) => props.theme.headerColor};
        }
    }
`;

const StyledNextSectionIcon = styled.div`
    display: flex;
    flex: 1 0 33%;
    order: 3;
    height: 50px;
    justify-content: flex-end;
    align-items: center;

    svg {
        width: 40px;
        height: 40px;
        * {
            fill: ${(props) => props.theme.headerColor};
        }
    }

    @media ${breakpoint.md} {
        width: 70px;
        height: 70px;

        svg {
            width: 70px;
            height: 70px;
        }
    }
`;

const StyledYMLogo = styled.span`
    background-image: url(${require("../assets/logo/ym-logo.svg")});
    background-size: contain;
    background-repeat: no-repeat;
    background-position: left center;
    cursor: pointer;

    @media ${breakpoint.md} {
        height: 40px;
    }
`;

const StyledEULogo = styled.span`
    background-image: url(${require("../assets/logo/eu-logo.png")});
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    cursor: pointer;

    @media ${breakpoint.md} {
        height: 40px;
    }
`;

const StyledSmartMrLogo = styled.span`
    background-image: url(${require("../assets/logo/smart-mr-logo.png")});
    background-size: contain;
    background-repeat: no-repeat;
    background-position: right center;
    cursor: pointer;

    @media ${breakpoint.md} {
        height: 50px;
    }
`;


const StyledTextLink = styled.a`
    color: rgba(255, 255, 255, .7);
`;

export const Cards = function (props) {
    const state = useContext(App.prototype.StateContext);
    const dispatch = useContext(App.prototype.DispatchContext);
    const cardContainerRef = React.createRef();

    const sectors = state.getIn(["sectors"]).toJS();

    const ExtraIcons = (
        <StyledExtraIcons>
            <StyledYMLogo
                onClick={() => {
                    window.location.href = "https://www.ym.fi/fi-FI";
                }}
            />
            <StyledEULogo
                onClick={() => {
                    window.location.href =
                        "https://ec.europa.eu/regional_policy/en/funding/erdf/";
                }}
            />
            <StyledSmartMrLogo
                onClick={() => {
                    window.location.href =
                        "https://www.interregeurope.eu/smart-mr/";
                }}
            />
        </StyledExtraIcons>
    );

    return (
        <Styled
            css={css`
                background-color: white;

                h1 {
                    font-weight: bold;
                }

                h2 {
                    font-size: 2.6em;
                }

                p {
                    font-size: 1.2em;
                }

                h1,
                h2 {
                    color: ${(props) => props.theme.headerColor};
                }

                h2 {
                    font-size: 1.8em;
                }
            `}
        >
            <Container>
                
                <StyledHeader
                    css={css`
                    padding-bottom: 0 !important;
                `}
                >
                    <Styled
                        css={css`
              width: 100%;
              flex: 1 0 auto;
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              margin-top: 2rem;

              @media ${breakpoint.md} and ${breakpointHeight.md} {
                margin-top: 6rem;
              }

              h1 {
                font-weight: 800;
                font-size: 2rem;
                line-height: 1.1;
                margin: .5rem 0;
                text-align: center;
                z-index: 1;
                width: 100%;
                margin-top: 1em;

                @media ${breakpoint.md} {
                  font-size: 3rem;
                }

                @media ${breakpoint.lg} {
                  font-size: 3.5em;
                }
              }

              p {
                width: 100%;
                font-size: 4vmin;
                margin-bottom: 4vh;
                margin-top: 0;
                text-align: center;
                z-index: 1;

                @media ${breakpoint.md} {
                  font-size: 1.2em;
                }

                @media ${breakpoint.lg} {
                }
              }
            `}
                    >
                        <h1>Low Carbon District Action Cards in Participatory District-Scale Urban Planning</h1>
                        
                        </Styled>
                        <img
                                width="100%"
                                height="100%"
                                src={require("../assets/infografiikat/LCD-kortit-EN.png")}
                                alt="Prosessikaavio, jossa ensimmäinen vaihe on 'Alueen ominaispiirteiden ja kehittämisedellytysten tunnistaminen', toinen vaihe on 'Toimenpiteiden priorisointi vuorovaikutteisessa työpajassa' ja kolmas, viimeinen vaihe on 'Vähähiilitiekartan koonti aikajanalle'. "
                            />
                </StyledHeader>
            </Container>
            <Section
                css={css`
                    background-color: #008782;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-direction: column;
                    h1 {
                        color: white;
                    }
                `}
            >
                <h1>Download Action Cards for Workshops</h1>
                <StyledButton
                    css={css`
                        padding: 0.7rem 2rem;
                        font-size: 1.3em;
                        font-family: "Montserrat", sans-serif;
                        font-weight: 600;
                        cursor: pointer;
                        margin: 0rem 1rem 1.5rem 1rem;
                        color: #008782;
                        background-color: #ffffff;
                    `}
                    onClick={event =>  window.open('https://ilmastoviisasalue.fi/pdf/LCD-kortit-EN.pdf', '_blank')}
                >
                    Download action cards (pdf)
                </StyledButton>
                <StyledTextLink href="https://ilmastoviisasalue.fi/pdf/LCD-kortit-EN-aukeamat.pdf" target="_blank">Download action cards in spreads (pdf)</StyledTextLink>
                

            </Section>
            <Section
                css={css`
                    background-color: #bde1e1;
                `}
            >
                <Container
                    css={css`
                        display: flex;
                        flex-direction: column;
                        flex-wrap: wrap;
                        padding: 2rem 0 1rem;

                        @media ${breakpoint.lg} {
                            padding: 3rem 0 4rem;
                        }
                    `}
                >
                    <Styled
                        css={css`
                            width: 90%;
                            margin: 0 auto 0.5rem auto;
                            text-align: center;
                            color: ${(props) => props.theme.headerColor};

                            p {
                                font-weight: 500;
                                line-height: 1.6em;
                            }

                            svg {
                                width: 70px;
                                height: 70px;

                                * {
                                    fill: ${(props) => props.theme.headerColor};
                                }
                            }

                            @media ${breakpoint.md} {
                                width: 80%;
                                margin: 0 auto 2rem auto;
                            }
                        `}
                    >   
                        <p>Integrating public and stakeholder views into urban planning process gives the planning decisions greater legitimacy. When the planning is founded on local knowledge and the possibilities to participation are wide, the identity of the district is strengthened.</p>
                        <p>Low Carbon District action cards are created to support participatory urban planning at the district scale. In order to implement participation effectively it should be integrated in the beginning of the planning process. The action cards introduce a set of concrete measures to be used when defining shared goals for district-scale planning. The concrete action cards make it easier to create dialogue between different sectors and with stakeholders and citizens. The action cards also benefit the planner by acting as a checklist in local detailed planning. </p>
                        <p>For arranging interactive workshops, the action cards can be downloaded as a printable version. Below you can find a description of how the cards have been used in the pilot phase. However, there is no one way to use the cards, be creative! Please share your best ideas with us so we can take them into account when developing the tool further. </p>

                        <img
                            css={css`
                                display: none;
                                @media ${breakpoint.md} {
                                    display: block;
                                }
                                width: 100%;
                                height: 100%;
                            `}
                            src={require("../assets/infografiikat/LCD-prosessi-vaaka-en.svg")}
                            alt="Prosessikaavio, jossa ensimmäinen vaihe on 'Alueen ominaispiirteiden ja kehittämisedellytysten tunnistaminen', toinen vaihe on 'Toimenpiteiden priorisointi vuorovaikutteisessa työpajassa' ja kolmas, viimeinen vaihe on 'Vähähiilitiekartan koonti aikajanalle'. "
                        />
                        <img
                            css={css`
                                @media ${breakpoint.md} {
                                    display: none;
                                }
                                width: 100%;
                                max-width: 400px;
                                height: 100%;
                            `}
                            src={require("../assets/infografiikat/LCD-prosessi-pysty-en.svg")}
                            alt="Prosessikaavio, jossa ensimmäinen vaihe on 'Alueen ominaispiirteiden ja kehittämisedellytysten tunnistaminen', toinen vaihe on 'Toimenpiteiden priorisointi vuorovaikutteisessa työpajassa' ja kolmas, viimeinen vaihe on 'Vähähiilitiekartan koonti aikajanalle'. "
                        />
                        
                    </Styled>
                </Container>
            </Section>



            <Section
                css={css`
                    background-color: white;
                    text-align: center;
                `}
            >
                <Container css={css`padding-bottom: 0;`}>
                    <h2>Pilot Case: Low Carbon Roadmap for Developing Malmi Station District</h2>
                    <Styled
                        css={css`
                            width: 100%;
                            margin: 0 auto 0rem auto;

                            p:first-child {
                                margin-top: 0;
                            }

                            @media ${breakpoint.lg} {
                                width: 70%;
                            }
                        `}
                    >
                        <p>
                        The LCD action cards have been piloted at the Malmi station district in northeast Helsinki, Finland by creating a low carbon roadmap which sets shared goals to develop the station district as a low carbon district. The roadmap was created as part of the envisioning work of the centre of Malmi with an exceptionally broad citizen and stakeholder participation before the local detailed plan will be developed.
                        </p>
                        <MalmiGraph />
                    </Styled>
                </Container>
            </Section>
            <Container>
                <Styled
                    css={css`
            border-bottom 1px solid #ccc;
            width: 90%;
            margin: 0 auto;
        `}
                />
            </Container>
        </Styled>
    );
};
